.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.image-container {
  filter: invert(1);
}

.custom-title {
  font-family: 'Darumadrop One', Arial, Helvetica, sans-serif;
  font-size: 4rem;
  color: #FFB300; 
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  letter-spacing: 0.1em;
  text-align: center;
}

.collab-title {
  font-family: 'Darumadrop One', Arial, Helvetica, sans-serif;
  font-size: 3rem;
  color: #FFB300; 
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  letter-spacing: 0.1em;
  text-align: center;
}

.small-text {
  font-family: 'Darumadrop One', Arial, Helvetica, sans-serif;
  font-size: 1rem;
  color: #FFB300; 
  text-align: center;
}



body {
  background-color: #FFF9E6 !important;
}

/*
Light Grey (#F5F5F5): This is a very subtle change, but making the background a light grey rather than pure white can be less harsh on the eyes and make the page feel a little softer.

Light Beige (#FAF0E6): This color is a very light tan or beige. It's still very neutral, but a little warmer than white or grey.

Off-White (#FAFAFA): This color is just a hint away from pure white, and it can make the page feel a little more sophisticated and less harsh.

Pale Gold (#FFF9E6): Since your text is a deep gold, a pale gold could create a monochromatic color scheme that feels harmonious and sophisticated.
*/


